import React, {
  useState,
  useContext,
  useRef,
  useCallback,
  useEffect,
} from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { CartContent } from '../CartContent'
import CartIcon from '../../../../icons/CartIcon'
import { useOnClickOutside } from '../../../../utils/use-on-click-outside'
import { isMobileSelector } from '$common/selectors'

import styles from './cart.module.scss'
import GenericButton from '../../../common/GenericButton'
import styled from 'styled-components'
import { Context } from '../../HeaderApp'

import hat from '../../assets/hat.svg'

export function Cart({ className, forceClosed }) {
  const [isOpen, setIsOpen] = useState(false)
  const { cartItems, onPlatform } = useContext(Context)
  const containerRef = useRef(null)
  const mobileContainerRef = useRef(null)
  const isMobile = useSelector(isMobileSelector)

  useEffect(() => setIsOpen(false), [forceClosed])

  const closeCart = useCallback(() => {
    if (isOpen) {
      setIsOpen(false)
    }
  }, [isOpen])
  useOnClickOutside(containerRef, closeCart)
  useOnClickOutside(mobileContainerRef, closeCart)

  const total = cartItems.reduce((acc, { amount }) => acc + amount, 0)
  const cartContent = <CartContent close={closeCart} items={cartItems} />

  return (
    <div className={classnames(styles.cart, className)} ref={containerRef}>
      <CartBtn
        $isOpen={isOpen}
        $notEmpty={cartItems.length > 0}
        $isVtb={onPlatform === 'vtb'}
        onClick={() => setIsOpen(!isOpen)}
      >
        {/* <NewYearHat src={hat} /> */}
        <CartIcon />
        {total}
      </CartBtn>
      {isOpen &&
        (isMobile ? (
          <div className={styles.cartMobileContainer}>
            <div ref={mobileContainerRef}>{cartContent}</div>
          </div>
        ) : (
          cartContent
        ))}
    </div>
  )
}

const NewYearHat = styled.img`
  width: 20px;
  position: absolute;
  left: 13px;
  top: 2px;
  z-index: 2;
  transform: rotate(-36deg);
`

const CartBtn = styled(GenericButton)`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: ${(p) => (p.$isOpen ? '#f44a36' : 'transparent')};
  padding: 10px 16px;
  transition: 0.25s;
  font-size: 20px;
  color: ${(p) => (p.$isOpen ? 'white' : p.$notEmpty ? '#f44a36' : 'black')};

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${(p) => (p.$isOpen ? '#f44a36' : 'rgba(0, 0, 0, 0.08)')};
  }

  ${(p) =>
    p.$isVtb &&
    `
    background: ${p.$isOpen ? '#002882' : 'transparent'};
    color: ${p.$isOpen ? 'white' : p.$notEmpty ? '#002882' : 'black'};

    &:hover {
      background-color: ${p.$isOpen ? '#002882' : 'rgba(0, 0, 0, 0.08)'};
    }
  `}

  @media (max-width: 400px) {
    padding: 8px 8px;
  }
`

Cart.propTypes = {
  className: PropTypes.string,
  forceClosed: PropTypes.bool,
}
